<template>
  <v-row v-if="valueNew" :style="{ backgroundColor: (error ? (error.error ? 'rgba(255, 0, 0, .1)' : '') : '') }">
    <v-col v-if="error && error.error" cols="12" class="d-flex justify-center red yellow--text font-weight-bold mb-2 text-capitalize">
      Error: {{ error.message }}
    </v-col>
    <v-col cols="12" class="d-flex justify-space-between align-center pb-2">
      <v-select
        v-model.number="valueNew.cabang_destination"
        :disabled="parseInt(valueNew.id) ? true : false"
        :items="$store.state.storeData.filter(r => !parseInt(r.type) && parseInt(r.id) !== parseInt(valueNew.cabang))"
        item-text="name"
        item-value="id"
        outlined
        dense
        hide-details
        label="Pilih Tujuan Pengiriman"
        @change="PRICING_GET()"
      />
    </v-col>
    <v-col cols="12" class="pt-2 pb-0 grey--text text--lighten-1">
      Pengirim
    </v-col>
    <v-col cols="12" class="d-flex justify-space-between align-center pt-2 pb-0">
      <v-text-field
        v-model.lazy="valueNew.shipping_sender_name"
        outlined
        dense
        hide-details
        label="Nama Pengirim"
        style="width: 47%;"
      />
      <v-spacer />
      <v-text-field
        v-model.lazy="valueNew.shipping_sender_phone"
        outlined
        dense
        hide-details
        label="No HP"
        style="width: 47%;"
        @keypress="$NumOnly"
      />
    </v-col>
    <v-col cols="12" class="pb-0 grey--text text--lighten-1">
      Penerima
    </v-col>
    <v-col cols="12" class="pt-2 pb-0">
      <div class="d-flex justify-space-between align-center pb-2">
        <v-text-field
          v-model.lazy="valueNew.shipping_name"
          outlined
          dense
          hide-details
          label="Nama Penerima"
          style="width: 47%;"
        />
        <v-spacer />
        <v-text-field
          v-model.lazy="valueNew.shipping_phone"
          outlined
          dense
          hide-details
          label="No HP Penerima"
          style="width: 47%;"
          @keypress="$NumOnly"
        />
      </div>
      <v-textarea
        v-model.lazy="valueNew.shipping_address"
        rows="2"
        outlined
        dense
        hide-details
        label="Alamat lengkap penerima"
      />
    </v-col>
    <v-col cols="12" class="pb-0 grey--text text--lighten-1 d-flex justify-space-between align-center">
      Berat Paket
      <strong class="secondary--text">
        {{ valueNew.type === 'UNIT' ? '1 UNIT' : (newShippingWeightActive(valueNew) + 'KG') }}
      </strong>
    </v-col>
    <v-col cols="12" class="pt-2">
      <v-select
        v-model.number="valueNew.product"
        :items="productData"
        :name="Math.random()"
        item-value="id"
        item-text="name"
        outlined
        dense
        hide-details
        class=""
        label="Pilih Produk"
      />
      <div class="d-flex justify-space-between align-center pt-2">
        <v-select
          v-model.number="valueNew.type"
          :items="[{ value: 'KG', name: 'Kilogram' }, { value: 'KUBIK', name: 'Kubik' }, { value: 'UNIT', name: 'Unit' }]"
          :name="Math.random()"
          item-value="value"
          item-text="name"
          outlined
          dense
          hide-details
          class=""
          label="Satuan Hitungan"
          style="width: 47%;"
          @change="SetPrice()"
        />
        <v-spacer />
        <v-text-field
          v-model.number="valueNew.weight_kg_actual"
          outlined
          dense
          hide-details
          suffix="Kg"
          class="d-block"
          label="Berat Aktual"
          style="width: 47%;"
          @keypress="$NumOnly"
          @keyup="SetPrice()"
        />
      </div>
      <div class="d-flex justify-space-between align-center pt-2">
        <v-text-field
          v-model.number="valueNew.weight_volume_length"
          outlined
          dense
          hide-details
          label="Panjang"
          suffix="cm"
          style="width: 31%;"
          @keyup="SetPrice()"
          @keypress="$NumOnly"
        />
        <v-spacer />
        <v-text-field
          v-model.number="valueNew.weight_volume_width"
          outlined
          dense
          hide-details
          label="Lebar"
          suffix="cm"
          style="width: 31%;"
          @keyup="SetPrice()"
          @keypress="$NumOnly"
        />
        <v-spacer />
        <v-text-field
          v-model.number="valueNew.weight_volume_height"
          outlined
          dense
          hide-details
          label="Tinggi"
          suffix="cm"
          style="width: 31%;"
          @keyup="SetPrice()"
          @keypress="$NumOnly"
        />
      </div>
      <div class="d-flex justify-space-between align-center pt-2">
        <v-select
          v-model.number="valueNew.price_id"
          :items="[{ price_detail: 'Custom Price', id: 0 }].concat(pricingData)"
          label="Tentukan Harga"
          item-text="price_detail"
          item-value="id"
          outlined
          dense
          hide-details
          style="width: 47%;"
          @change="SetPrice()"
        />
        <v-spacer />
        <v-text-field
          v-model.number="valueNew.price"
          :disabled="parseInt(valueNew.price_id) ? true : false"
          :name="Math.random()"
          outlined
          dense
          hide-details
          :placeholder="'Harga ' + (valueNew.type === 'UNIT' ? '1 UNIT' : (newShippingWeightActive(valueNew) + 'KG')) + (customerDiscount.price && (customerDiscount.price > 0) ? (' (Discount ' + (customerDiscount.percent) + '% menjadi ' + ($price(customerDiscount.price)) + ')') : '')"
          prefix="Rp"
          dark
          color="black"
          class="black white--text font-weight-bold"
          style="width: 47%;"
          @keypress="$NumOnly"
        />
      </div>
    </v-col>
  </v-row>
</template>

<script>
let updateTimeout = null
export default {
  props: {
    productData: {
      type: Array,
      default: () => []
    },
    error: {
      type: Object,
      default: () => null
    },
    value: {
      type: Object,
      default: () => null
    },
    customer: {
      type: Object,
      default: () => null
    }
  },
  data: () => ({
    valueNew: null,
    pricingData: []
  }),
  computed: {
    customerDiscount () {
      if (this.valueNew && parseInt(this.valueNew.price_id) && this.pricingData.length) {
        const p = this.pricingData.find(r => parseInt(r.id) === parseInt(this.valueNew.price_id))
        if (p) {
          if (this.customer && parseInt(this.valueNew.customer)) {
            const c = this.customer
            if (c) {
              const discount = ((parseInt(c.type_price) / 100) * (parseInt(p.price) || 0))
              return {
                price: parseInt(p.price) - discount,
                percent: parseInt(c.type_price) || 0
              }
            }
          }
        }
      }
      return {
        price: 0,
        percent: 0
      }
    }
  },
  watch: {
    // value: {
    //   immediate: true,
    //   handler (v) {
    //     console.log('1')
    //     if (v) {
    //       this.valueNew = Object.assign({}, v)
    //     }
    //   }
    // },
    valueNew (v) {
      if (v) {
        if (updateTimeout) {
          clearTimeout(updateTimeout)
        }
        updateTimeout = setTimeout(() => {
          this.$emit('input', Object.assign(v))
        }, 300)
      }
    }
  },
  mounted () {
    if (this.value) {
      this.valueNew = Object.assign({}, this.value)
      this.PRICING_GET()
    }
  },
  methods: {
    async PRICING_GET () {
      this.pricingData = []
      const origin = this.$store.state.storeData.find(r => parseInt(r.id) === parseInt(this.valueNew.cabang))
      const destination = this.$store.state.storeData.find(r => parseInt(r.id) === parseInt(this.valueNew.cabang_destination))
      if (origin && destination && parseInt(origin.city_id) && parseInt(destination.city_id)) {
        this.valueNew.shipping_origin = parseInt(origin.city_id) || 0
        this.valueNew.shipping_destination = parseInt(destination.city_id) || 0
        const q = '?city_origin=' + (parseInt(origin.city_id) || 0) + '&city_destination=' + (parseInt(destination.city_id) || 0)
        await this.$store.dispatch('logistic/SHIPPING_PRICING_GET', q)
          .then((res) => {
            if (res.status) {
              this.pricingData = res.data.data || []
            }
          })
      }
    },
    SetPrice () {
      if (this.valueNew) {
        this.valueNew.price = 0
        if (parseInt(this.valueNew.price_id) && this.pricingData.length) {
          const p = this.pricingData.find(r => parseInt(r.id) === parseInt(this.valueNew.price_id))
          if (p) {
            let cc = 0
            if (this.customerDiscount.price) {
              cc = this.customerDiscount.price
            } else {
              cc = (parseInt(p.price) || 0)
            }
            this.valueNew.price = (parseInt(cc) || 0) * (parseInt(this.newShippingWeightActive(this.valueNew)) || 0)
          }
        }
      }
    },
    newShippingWeightActive (m) {
      const weight = parseFloat(m.weight_kg_actual) || 0
      const length = parseFloat(m.weight_volume_length) || 0
      const width = parseFloat(m.weight_volume_width) || 0
      const height = parseFloat(m.weight_volume_height) || 0

      let weightVolume = 0
      if (m.type === 'UNIT') {
        return 1
      } else if (m.type === 'KG') {
        return weight
      } else if (m.type === 'KUBIK') {
        weightVolume = parseFloat(parseFloat((length * width * height) / 4000).toFixed(2))
      }

      if (length && width && height) {
        weightVolume = parseFloat(parseFloat((length * width * height) / 4000).toFixed(2))
      }
      if (weight && weightVolume) {
        return (weight > weightVolume) ? weight : weightVolume
      } else {
        return weight || weightVolume
      }
    }
  }
}
</script>

<style>
</style>
